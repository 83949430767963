<template>
  <v-dialog v-model="dialog" :key="id" max-width="90%" height="95%">
    <v-card class="mx-auto" color="white" outlined>
      <div v-if="currentCode" class="edit-form py-3">
        <v-card-actions class="pt-3">
          <p class="headline">Editar Información de Código Existente</p>
          <v-spacer></v-spacer>
          <v-btn color="green accent-3" class="body-2 font-weight-bold" rounded @click.native="closeDialog">OK</v-btn>
          <v-dialog v-model="haschanges" width="40%">
            <v-card height="50%">
              <v-card-title></v-card-title>
              <v-card-text > 
                <h3 class="text-center">Existen cambios sin guardar, ¿qué desea 
                hacer con estos cambios?</h3>
             </v-card-text>
              <v-card-actions >
                <v-btn plain @click="closediag()">descartar cambios y salir</v-btn>
                <v-spacer />
                <v-btn plain @click="savechanges()">guardar cambios y salir</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
        <v-divider class="my-5" />
        <v-form ref="form">
          <v-row>
            <v-col cols="12" md="4">
              <v-img contain :lazy-src="'https://api.api-stock.xyz/foto/no-picture.jpg'" max-width="350"
                max-height="350" :src="'https://api.api-stock.xyz/foto/'+currentCode.CODIGO+'.jpg'"
                @click.stop="imgzoom()" />
            </v-col>

            <v-dialog v-model="imgdialog">
              <v-img contain :lazy-src="'https://api.api-stock.xyz/foto/no-picture.jpg'"
                :src="'https://api.api-stock.xyz/foto/'+currentCode.CODIGO+'.jpg'" @click.stop="imgclose()" />
            </v-dialog>

            <v-col cols="12" md="8">
              <v-card class="mx-auto" color="cyan lighten-5" outlined>
                <div id="overlayed_content">
                  <v-tabs background-color="transparent" grow>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab> Información </v-tab>
                    <v-tab-item>
                      <v-row>
                        <v-col cols="12" md="2">
                          <v-text-field v-model="currentCode.CODIGO" :rules="[(v) => !!v || 'Código is required']"
                            label="Código" readonly />
                        </v-col>
                        <v-col cols="12" md="10">
                          <v-text-field v-model="DESC_CODIGO"
                            :rules="[(v) => !!v || 'Description is required']" label="Descripción" counter
                            maxlength="60" required :readonly="!mods" @change="enbtn()"/>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3">
                          <v-text-field v-model="currentCode.UNIDAD_MEDIDA" label="Unidad" readonly />
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field v-model="precioart" label="Precio $" readonly />
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field v-model="currentCode.SALDO" label="Saldo" readonly />
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field v-model="saldoart" label="Saldo total $" readonly />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field v-model="currentCode.DESC_ALMACEN" label="Almacén" readonly />
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-select v-model="CRITICIDAD" :items="critics" label="Criticidad" :readonly="!mods" @change="enbtn()"/>
                        </v-col>
                        <v-col cols="12" md="2">
                          <v-text-field v-model="MIN_STK" label="Stock Mínimo" type="number" :readonly="!mods" @change="enbtn()"/>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3">
                          <v-text-field v-model="tipoart" label="Tipo" readonly />
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field v-model="FAMILIA" label="Familia" :readonly="!mods" @change="enbtn()"/>
                        </v-col>
                        <v-col cols="12" md="">
                          <v-text-field v-model="SUBFAMILIA" label="Subfamilia" :readonly="!mods" @change="enbtn()"/>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3">
                          <v-text-field v-model="DATO_TEXTO1" label="Atributo 1" :readonly="!mods" @change="enbtn()"/>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field v-model="DATO_TEXTO2" label="Atributo 2" :readonly="!mods" @change="enbtn()"/>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field v-model="DATO_TEXTO3" label="Atributo 3" :readonly="!mods" @change="enbtn()"/>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field v-model="DATO_TEXTO4" label="Atributo 4" :readonly="!mods" @change="enbtn()"/>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                    <v-tab> Consumos </v-tab>
                    <v-tab-item>
                      <div>
                        <apexchart height=200 :options="chartOptions" :series="series" />
                      </div>
                      <div>
                        <v-data-table dense :headers="headers" :items="salidas" />
                      </div>
                    </v-tab-item>
                  </v-tabs>
                  <v-card-actions v-if="mods">
                    <v-spacer />
                    <v-btn plain :disabled="d" @click="savechanges()">guardar cambios</v-btn>
                  </v-card-actions>
                  <!-- overlay here -->
                  <v-overlay :absolute="true" :opacity="0.8" :value="overlay">
                    <v-progress-circular :rotate="-90" :size="160" :width="15" :value="value" color="green">
                      {{ value }} %
                    </v-progress-circular>
                  </v-overlay>
                </div>
                <!-- overlay end -->
              </v-card>
            </v-col>
          </v-row>
          <label><strong>Status:</strong></label>
          {{ currentCode.STATUS ? "Repuesto" : "Otros" }}
        </v-form>
        <p class="mt-3">{{ message }}</p>
      </div>
      <div v-else>
        <br>
        <p>Por favor selecciona un código...</p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import CodeDataService from "../../services/CodeDataService";
export default {
  name: "codigoDlg",
  data() {
    return {
      //valores por defecto
      d:true,
      haschanges:false,
      // mods:false,
      dialog:false,
      imgdialog:false,
      overlay: false,
      currentCode: null,
      message: "",
      precioart: null,
      saldoart:null,
      tipoart: null,
      DESC_CODIGO:'',
      FAMILIA:'',
      SUBFAMILIA:'',
      DATO_TEXTO1:'',
      DATO_TEXTO2:'',
      DATO_TEXTO3:'',
      DATO_TEXTO4:'',
      CRITICIDAD:'',
      MIN_STK:0,
      critics:['CRITICO','CONSUMIBLE','A PEDIDO','N.C.','OBSOLETO'],
      interval: {},
      value: 0,
      id:"",
      //datos tabla salidas
        salidas:[],
        headers: [
        { text: "Tipo", value: "TIPO_TRX", align: "start", sortable: true, filterable: true},
        { text: "C. Costo", value: "DESC_CECO", sortable: true, filterable:true },
        { text: "Código", value: "CODIGO", sortable: false },
        { text: "Descripción", value: "DESC_CODIGO", sortable: false },
        { text: "Vale", value: "TRX_MVX", sortable: true },
        { text: "Cantidad", value: "CONSUMO", sortable: true, filterable: true },
        { text: "Fecha", value: "FECHA", sortable: true },
        { text: "Acción", value: "actions", sortable: false }
        
      ],
      //datos del grafico
      chartOptions: {
        chart:{type:'bar'},
        plotOptions: { bar: {stacked:true} },
        xaxis: { categories: ['Sin consumos registrados dentro del último año'] }, //default en caso de encontrar nada
        yaxis: [{ title:{text:'Consumos'} },
                { opposite:true, title:{text:'N° Salidas'} }]
      },
      series: [{ name: 'Consumos', data: [0] },
               { name: 'N° Salidas', data: [0] }]
    };
  },
  beforeDestroy () {
      clearInterval(this.interval)
    },
  computed: {
    mods: function () {
        let user = JSON.parse(sessionStorage.getItem('user'))
        if (user.roles.includes('ROLE_SUPERVISOR')) {
          return true
        } else {
          return false
        }
    }
  },

  methods: {
      open: function (id) {
        this.id = id;
        clearInterval(this.interval)
        this.value = 0
        CodeDataService.get(id)
          .then((response) => {
            this.overlay = true
            this.dialog = true
            this.currentCode = response.data;
            this.DESC_CODIGO = response.data.DESC_CODIGO
            this.CRITICIDAD = response.data.CRITICIDAD
            this.MIN_STK = response.data.MIN_STK
            this.FAMILIA = response.data.FAMILIA
            this.SUBFAMILIA = response.data.SUBFAMILIA
            this.DATO_TEXTO1 = response.data.DATO_TEXTO1
            this.DATO_TEXTO2 = response.data.DATO_TEXTO2
            this.DATO_TEXTO3 = response.data.DATO_TEXTO3
            this.DATO_TEXTO4 = response.data.DATO_TEXTO4
            this.precioart = "$ " + Math.round(response.data.PRECIO_MEDIO).toLocaleString('es-CL');
            this.saldoart = "$ " + Math.round(response.data.TOTAL_SALDO).toLocaleString('es-CL');
            this.tipoart = response.data.CLASE_1 + " " + response.data.CLASE_2
            const alm = response.data.ALMACEN;
            const codi = response.data.CODIGO;
            this.updateCharts(alm, codi)
            this.retrieveSalida(alm, codi)
          })
          .catch((e) => {
            console.log(e);
          });
      },

      imgzoom() {
        this.imgdialog = true
      },

      imgclose() {
        this.imgdialog = false
      },

      getCode(id) {
        //traer datos del codigo desde base stocks diario
        CodeDataService.get(id)
          .then((response) => {
            this.currentCode = response.data;
            this.precioart = "$ " + Math.round(response.data.PRECIO_MEDIO).toLocaleString('es-CL');
            this.saldoart = "$ " + Math.round(response.data.TOTAL_SALDO).toLocaleString('es-CL');
            this.tipoart = response.data.CLASE_1 + " " + response.data.CLASE_2
            const alm = response.data.ALMACEN;
            const codi = response.data.CODIGO;
            //consultar y actualizar datos de grafico
            this.updateCharts(alm, codi)
          })
          .catch((e) => {
            console.log(e);
          });

      },
      enbtn() {
        if (this.d === true ) {
        this.d=false
        }
      },

      updateCharts(alm, codi) {
        let params = {}
          params["alm"] = alm
          params["codi"] = codi
        //iniciar conteo para desvancer pagina de carga
        this.interval = setInterval(() => {
          if (this.value === 100) {
            this.overlay = false
          }
          this.value += 20
        }, 300)
        //buscar y calcular valores del grafico
        CodeDataService.getconsumos(params)
          .then((response) => {
            this.series[0].data = response.data[0].consumos
            this.series[1].data = response.data[0].salidas
            this.chartOptions.xaxis.categories = response.data[0].categoria
          })
      },

      retrieveSalida(alm, codi) {
        let params = {}
          params["alm"] = alm
          params["codi"] = codi
        CodeDataService.getsalidas(params)
          .then((response) => {
            this.salidas = response.data.map(this.getDisplaySalidas)
            this.salidas.forEach(el => {
              var date = new Date(el.FECHA);
              var options = {
                year: 'numeric',
                month: 'numeric',
                day: '2-digit'
              };
              var _resultDate = new Intl.DateTimeFormat('es-LA', options).format(date);
              el.FECHA = _resultDate.replace(/\//g, '-');
            });
          })
          .catch((e) => {
            console.log(e);
          });
      },
      getDisplaySalidas(salidas) {
        return {
          TIPO_TRX: salidas.TIPO_TRX,
          DESC_CECO: salidas.DESC_CECO,
          CODIGO: salidas.CODIGO,
          DESC_CODIGO: salidas.DESC_CODIGO,
          TRX_MVX: salidas.TRX_MVX,
          CONSUMO: salidas.CONSUMO,
          FECHA: salidas.FECHA.toLocaleString(),
        };
      },
      closediag (){
        this.dialog=false
      },
      closeDialog() {
      if (this.d ==true) {
        this.dialog = false;
        } else {
        this.haschanges=true
        }
      },
      savechanges() {
        
        let data = {}
        data['CRITICIDAD'] = this.CRITICIDAD
        data['MIN_STK'] = this.MIN_STK
        CodeDataService.update(this.id, data)
        let data2 = {}
        data2['CODIGO'] = this.currentCode.CODIGO
        data2['DESC_CODIGO']= this.DESC_CODIGO
        data2['FAMILIA'] = this.FAMILIA
        data2['SUBFAMILIA'] = this.SUBFAMILIA
        data2['DATO_TEXTO1'] = this.DATO_TEXTO1
        data2['DATO_TEXTO2'] = this.DATO_TEXTO2
        data2['DATO_TEXTO3'] = this.DATO_TEXTO3
        data2['DATO_TEXTO4'] = this.DATO_TEXTO4
        CodeDataService.updatecode(data2)
        
        this.dialog = false;
      }
  },
};
</script>

<style>
.edit-form {
  max-width: 1100px;
  margin: auto;
}
</style>