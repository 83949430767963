import http from "../http-common";
import authHeader from './Auth-header';

class CodeDataService {
  getAll(params) {
    return http.post("/allcode/", params, { headers: authHeader()});
  }

  get(id) {
    return http.get(`/allcode/code/${id}`, {headers: authHeader()});
  }

  getconsumos(params) {
    return http.get("/allcode/consumo", { headers: authHeader(), params })
  }

  getsalidas(params) {
    return http.get("/allcode/salida", { headers: authHeader(), params })
  }

  getFamilia(data) {
    return http.post('/familia', data, {headers:authHeader()})
  }

  getalmacenes(data) {
    return http.post('/almacenes', data, {headers:authHeader()})
  }

  getSubfamilia(data) {
    return http.post('/subfamilia', data, {headers:authHeader()})
  }

  update(id, data) {
    return http.post(`/allcode/code/${id}`, data, {headers: authHeader()});
  }

  updatecode (data) {
    return http.post('/allcode/desc', data, {headers: authHeader()})
  }

  dashdata ( data ) {
    return http.post('/stock/', data , {headers:authHeader()})
  }

  dashalmdata ( data ) {
    return http.post('/almstock/', data, {headers:authHeader()})
  }
  
  dashcritdata ( data ) {
    return http.post('/critstock/', data, {headers:authHeader()})
  }

}

export default new CodeDataService();