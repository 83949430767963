<template>
  <div>
    <div id="the-content">
      <v-row align="center" class="list mx-auto">
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="searchCo"
            append-icon="mdi-magnify"
            type="number"
            clearable
            label="Buscar por código"
            @click:append="retrieveCodes()"
          />
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field v-model="search" clearable multiple label="Buscar por descripcion" />
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-select v-model="searchEmp" :items="empresas" clearable label="Seleccione empresa" @change="getalm()" />
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-select v-model="searchAlm" :items="almacenes" clearable label="Seleccione almacén" multiple />
        </v-col>
      </v-row>
      <v-row align="center" class="list mx-auto">
        <v-col class="d-flex" cols="6" sm="3">
          <v-select v-model="searchCrit" :items="critics" clearable chips label="Seleccione criticidad" multiple />
        </v-col>
        <v-col class="d-flex" cols="6" sm="3">
          <v-autocomplete
            v-model="family"
            :items="families"
            clearable
            chips
            label="Seleccione familia"
            @change="getsubfam()"
          />
        </v-col>
        <v-col class="d-flex" cols="6" sm="3">
          <v-autocomplete v-model="subfamily" :items="subfamilies" clearable chips label="Seleccione subfamilia" />
        </v-col>
        <v-col cols="12" lg="1">
          <v-btn
            large
            @click="
              page = 1;
              retrieveCodes();
            "
          >
            Buscar
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" class="list mx-auto">
        <v-col cols="6" md="2">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Articulos/Página"
            centered
            @change="handlePageSizeChange"
          />
        </v-col>
        <v-col cols="12" md="8">
          <v-pagination
            v-model="page"
            :length="totalPages"
            total-visible="10"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          />
        </v-col>
        <v-col cols="12" md="2" class="fuente">
          <v-checkbox v-model="saldo" label="Sólo articulos con saldo disponible"></v-checkbox>
        </v-col>
      </v-row>
      <v-card class="list mx-auto" tile>
        <v-row>
          <v-card-title>Stocks</v-card-title>
          <v-spacer />
          <v-card-subtitle v-text="'Mostrando items ' + currentItems + ' de ' + totalItems" />
        </v-row>

        <v-data-table :headers="headers" :items="codigos" disable-pagination :hide-default-footer="true">
          <template v-slot:[`item.actions`]="{ item }">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-icon
                :elevation="hover ? 16 : 2"
                :class="{ 'on-hover': hover }"
                small
                class="mr-2"
                @click="editCode(item.id)"
              >
                mdi-information-variant
              </v-icon>
            </v-hover>
          </template>
        </v-data-table>
        <v-card-actions v-if="codigos.length > 0">
          <v-pagination
            v-model="page"
            :length="totalPages"
            total-visible="7"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange"
          ></v-pagination>
        </v-card-actions>
      </v-card>
    </div>
    <v-overlay :opacity="0.8" :value="overlay">
      <!-- <v-progress-circular indeterminate size="150">
        descargando...
      </v-progress-circular> -->
      Descargando archivo solicitado...
      <v-progress-linear height="10" indeterminate striped color="deep-orange" />
    </v-overlay>
    <codigoDialog ref="codetails" />
  </div>
</template>

<script>
import CodeDataService from "../../services/CodeDataService";
import codigoDlg from "./Code";
import XLSX from "xlsx";
export default {
  name: "codes-list",
  components: {
    codigoDialog: codigoDlg,
  },
  // props:{
  //     id:{type:String, required:true},
  //   },
  data() {
    return {
      // dialog: false,
      overlay: false,
      codigos: [],
      almacenes: [],
      families: [],
      subfamilies: [],
      family: undefined,
      subfamily: undefined,
      search: "",
      searchCrit: "",
      searchEmp: "",
      searchAlm: "",
      saldo: false,
      searchCo: undefined,
      headers: [
        { text: "Almacen", value: "ALMACEN", align: "start", sortable: false },
        { text: "Código", value: "CODIGO", sortable: false },
        { text: "Descripción", value: "DESC_CODIGO", sortable: false },
        { text: "Criticidad", value: "CRITICIDAD", sortable: false },
        { text: "Saldo", value: "SALDO", align: "center", sortable: false },
        { text: "Stock min.", value: "MIN_STK", align: "center", sortable: false },
        { text: "Acción", value: "actions", align: "center", sortable: false },
      ],
      page: 1,
      totalPages: 0,
      currentItems: "",
      totalItems: 0,
      pageSize: 100,
      pageSizes: [100, 250, 500],
      critics: ["CRITICO", "CONSUMIBLE", "A PEDIDO", "N.C.", "OBSOLETO"],
      empresas: [
        "AGRICOLA ARIZTIA LTDA",
        "AGRICOLA LLUTA S.A.",
        "AGRICOLA ROBLEDAL LTDA.",
        "AGRICOLA TARAPACA S.A.",
        "AGROINDUSTRIAL ARICA S.A.",
        "AGROINDUSTRIAL EL PAICO S.A.",
        "ARIZTIA COMERCIAL LTDA",
        "INDUSTRIAL OCHAGAVIA LTDA.",
        "SERVICIOS DE MANTENCION LTDA.",
        "TECNOLOGIA Y ALIMENTOS LTDA.-",
      ],
    };
  },
  methods: {
    getRequestParams(page, pageSize, searchCrit, searchEmp, searchAlm, search, searchCo, family, subfamily, saldo) {
      let params = {};

      if (page) {
        params["page"] = page - 1;
      }
      if (pageSize) {
        params["size"] = pageSize;
      }
      if (family) {
        params["family"] = family;
      }
      if (subfamily) {
        params["subfamily"] = subfamily;
      }
      if (searchCrit) {
        params["crit"] = searchCrit;
      }
      if (searchEmp) {
        params["emp"] = searchEmp;
      }
      if (searchAlm) {
        params["alm"] = searchAlm;
      }
      if (searchCo) {
        params["codi"] = searchCo;
      }
      if (search) {
        params["desc"] = search;
      }
      if (saldo) {
        params["saldo"] = saldo;
      }
      return params;
    },
    retrieveCodes() {
      const params = this.getRequestParams(
        this.page,
        this.pageSize,
        this.searchCrit,
        this.searchEmp,
        this.searchAlm,
        this.search,
        this.searchCo,
        this.family,
        this.subfamily,
        this.saldo
      );
      CodeDataService.getFamilia().then((response) => {
        this.families = response.data.map((el) => el._id);
      });
      CodeDataService.getAll(params)
        .then((response) => {
          const { codigos, totalPages, currentItems, totalItems } = response.data;
          this.codigos = codigos.map(this.getDisplayCode);
          this.totalPages = totalPages;
          this.currentItems = currentItems;
          this.totalItems = totalItems;
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("auth/logout");
          this.$router.push("/login");
        });
    },
    exportCodes() {
      const params = this.getRequestParams(
        this.page,
        this.pageSize,
        this.searchCrit,
        this.searchAlm,
        this.search,
        this.searchCo,
        this.family,
        this.subfamily
      );
      this.overlay = true;
      CodeDataService.getAll(params)
        .then((response) => {
          let arrcodigos = [];
          for (let i = 0; i < response.data.codigos.length; i++) {
            const arrcode = {
              DESC_EMPRESA: response.data.codigos[i]["DESC_EMPRESA"],
              ALMACEN: response.data.codigos[i]["ALMACEN"],
              DESC_ALMACEN: response.data.codigos[i]["DESC_ALMACEN"],
              CODIGO: response.data.codigos[i]["CODIGO"],
              DESC_CODIGO: response.data.codigos[i]["DESC_CODIGO"],
              CRITICIDAD: response.data.codigos[i]["CRITICIDAD"],
              CLASE_1: response.data.codigos[i]["CLASE_1"],
              CLASE_2: response.data.codigos[i]["CLASE_2"],
              FAMILIA: response.data.codigos[i]["FAMILIA"],
              SUBFAMILIA: response.data.codigos[i]["SUBFAMILIA"],
              DATO_TEXTO1: response.data.codigos[i]["DATO_TEXTO1"],
              DATO_TEXTO2: response.data.codigos[i]["DATO_TEXTO2"],
              PRECIO_MEDIO: response.data.codigos[i]["PRECIO_MEDIO"],
              SALDO: response.data.codigos[i]["SALDO"],
              TOTAL_SALDO: response.data.codigos[i]["TOTAL_SALDO"],
            };
            arrcodigos.push(arrcode);
          }
          var codig = XLSX.utils.json_to_sheet(arrcodigos);
          var wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, codig, "STOCK");
          XLSX.writeFile(wb, "STOCK.xlsx");
          this.overlay = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveCodes();
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.retrieveCodes();
    },
    refreshList() {
      this.retrieveCodes();
    },
    getsubfam() {
      let data = {};
      data["family"] = this.family;
      CodeDataService.getSubfamilia(data).then((response) => {
        this.subfamilies = response.data.map((el) => el._id);
      });
    },
    async getalm() {
      let data = {};
      data["emp"] = this.searchEmp;
      await CodeDataService.getalmacenes(data).then((response) => {
        this.almacenes = response.data.map(this.getalms);
      });
    },
    async editCode(id) {
      this.id = id;
      await this.$refs.codetails.open(id);
      // this.$router.push({ name: "code-details", params: { id: id } });
    },
    getDisplayCode(codigos) {
      return {
        id: codigos.id,
        CODIGO: codigos.CODIGO,
        DESC_CODIGO: codigos.DESC_CODIGO,
        ALMACEN: codigos.ALMACEN,
        CRITICIDAD: codigos.CRITICIDAD,
        SALDO: codigos.SALDO,
        MIN_STK: codigos.MIN_STK,
        ALM_COD: codigos.ALM_COD,
      };
    },
    getalms(alms) {
      return {
        text: alms.nom,
        value: alms._id,
      };
    },
  },
  mounted() {
    this.retrieveCodes();
    // this.overlay = false
  },
};
</script>

<style>
.list {
  max-width: 1200px;
  margin: auto;
}
.fuente {
  font-size: 20px;
}
</style>
